<template>
  <div>
    <form enctype="multipart/form-data" novalidate>
      <div class="dropbox" :style="getDropBoxStyle(pro.style)">
        <input
        v-if="!isEditorPage"
          type="file"
          multiple
          :name="uploadFieldName"
          accept="image/*, video/*, .txt, application/pdf"
          :disabled="isSaving || isFailed || isEditorPage"
          @change="
            filesChange($event.target.files);
            fileCount = $event.target.files.length;
          "
          class="input-file"
        />
        
        <div v-if="isInitial">
          <p
            v-if="pro.fileTextLine1"
            class="m-0"
            :style="getDropBoxTextStyle(pro.style)"
          >
            {{ pro.fileTextLine1 }} <span v-if="pro.isRequired" class="text-danger">*</span>
          </p>
          <p
            v-if="pro.fileTextLine2"
            class="m-0"
            :style="getDropBoxTextStyle(pro.style)"
          >
            {{ pro.fileTextLine2 }}
          </p>
        </div>

        <p v-if="isSaving">Uploading {{ fileCount }} files...</p>

        <div v-if="isFailed">
          <p :style="getDropBoxTextStyle(pro.style)">
            {{ uploadError }}
          </p>
         
        </div>
        <div v-if="isSuccess">
          <p :style="getDropBoxTextStyle(pro.style)">
           Uploaded {{ fileCount }} file(s) successfully.
          </p>
          
         
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import cssFunctionMixin from "../customize/mixin/cssFunction";
import axios from "axios"
const STATUS_INITIAL = 0,
  STATUS_SAVING = 1,
  STATUS_SUCCESS = 2,
  STATUS_FAILED = 3;
export default {
  mixins: [cssFunctionMixin],
  props: ["pro", "isDesktopView","apiToken"],
  data() {
    return {
      uploadedFiles: [],
      uploadError: null,
      currentStatus: null,
      uploadFieldName: "Data",
    };
  },
  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    },
    getMaxFileUploadSize() {
      // Base Unit 1 MB = 1048576 Bytes
      return this.pro.maxFileSize * 1048576;
    },
    isEditorPage(){
        return this.$route.name == 'Create' ? true : false
    }
  },
  methods: {
    reset() {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL;
      this.uploadedFiles = [];
      this.uploadError = null;
    },
    filesChange(fileList) {
      if (!fileList.length ) return;

      if(fileList.length > this.pro.totalFiles){
        this.uploadError = `Only ${this.pro.totalFiles} file(s) allowed.`;
        this.currentStatus = STATUS_FAILED;

        setTimeout(() => {
          this.reset();
        }, 2000);
        return;
      }

      // Check if File size is within limit
      let fileSize = Array.from(fileList).reduce(
        (acc, file) => acc + file.size,
        0
      );
      if (fileSize > this.getMaxFileUploadSize) {
        this.uploadError = `File is too large. Max ${this.pro.maxFileSize} MB allowed.`;
        this.currentStatus = STATUS_FAILED;

        setTimeout(() => {
          this.reset();
        }, 2000);
        return;
      }

      const formData = new FormData();

     
      for(var i = 0; i < fileList.length; i++ ){
  let file = fileList[i];
  formData.append('files[' + i + ']', file);
}

formData.append(' api_token',this.apiToken)

      this.saveData(formData);
    },

    saveData(formData) {
     
      this.currentStatus = STATUS_SAVING;
      try {
        axios.post(`/api/uploadQuizFile
`, formData, { headers: {'Content-Type': 'multipart/form-data'} }).then(res=>{
 if(res.status == 200){
    this.uploadedFiles = res.data?.data
    this.$emit('updateUploadedFiles', res.data?.data)
    this.currentStatus = STATUS_SUCCESS;
 }
 else{
  this.uploadError = res.data.data[0];
        this.currentStatus = STATUS_FAILED;
 }
})
      } catch (error) {
         this.uploadError = error;
        this.currentStatus = STATUS_FAILED;
      }
    },
  },
  mounted() {
    this.reset();
  },
};
</script>

<style scoped>
.input-file {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: var(--hoverColor);
}
</style>
